import { PokemonQueryResult } from '../types';

export const getPokemonList = async (limit: number = 100, offset: number = 0): Promise<PokemonQueryResult> => {
    const res = await fetch(`https://pokeapi.co/api/v2/pokemon?limit=${limit}&offset=${offset}`);
    if (!res.ok) {
        throw new Error(`Error getting Pokemon list: status ${res.status} ${res.statusText}`);
    } else {
        return (await res.json()) as PokemonQueryResult;
    }
};
