import { Pokemon } from '../types';

export const getPokemon = async (pokemonRef: string) => {
    const res = await fetch(`https://pokeapi.co/api/v2/pokemon/${pokemonRef}`);
    if (!res.ok) {
        throw new Error(`Error fetching Pokemon ${pokemonRef}, error ${res.status} ${res.statusText}`);
    } else {
        return (await res.json()) as Pokemon;
    }
};
