import { Button, Card, Container } from 'react-bootstrap';
import { Link } from 'wouter';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { PokemonQueryResult } from './types';
import { getPokemonList } from './util/getPokemonList';
import { titleCase } from './util/titleCase';

// Adjust this to change the number of Pokemon shown on each page
const ITEMS_PER_PAGE = 24;

export default function PokemonList() {
    const [pokedex, setPokedex] = useState<PokemonQueryResult | null>(null);
    const [cursor, setCursor] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        getPokemonList(ITEMS_PER_PAGE, cursor)
            .then((list) => {
                setPokedex(list);
                setLoading(false);
            });
    }, [cursor]);

    return (
        <Container className="mt-5 mb-5">
            <h1 className="mb-4">Pokémon!</h1>
            <div className="grid">
                {!loading && pokedex && pokedex.results.length > 0 ? pokedex.results.map((link) => (
                    <Card>
                        <Card.Body className="card">
                            <Card.Title
                                className="m-0"
                                style={{
                                    width: 'calc(100% - 12px)',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {titleCase(link.name)}
                            </Card.Title>
                            <Link href={`/pokemon/${link.name}`}>
                                <Button variant="dark">
                                    Details
                                </Button>
                            </Link>
                        </Card.Body>
                    </Card>
                )) : (new Array(ITEMS_PER_PAGE)).fill('').map(() => <Skeleton height={70} />)}
            </div>
            <div
                className="mt-5"
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="dark"
                    onClick={() => {
                        setCursor(Math.max(0, cursor - ITEMS_PER_PAGE));
                    }}
                    disabled={!pokedex || cursor < ITEMS_PER_PAGE}
                >
                    Previous
                </Button>
                <span>{`Page ${Math.ceil(cursor / ITEMS_PER_PAGE) + 1} of ${Math.ceil((pokedex?.count ?? ITEMS_PER_PAGE) / ITEMS_PER_PAGE)}`}</span>
                <Button
                    variant="dark"
                    onClick={() => {
                        setCursor(Math.min(cursor + ITEMS_PER_PAGE, Math.ceil((pokedex?.count ?? ITEMS_PER_PAGE) / ITEMS_PER_PAGE) * ITEMS_PER_PAGE));
                    }}
                    disabled={!pokedex || cursor >= pokedex.count - ITEMS_PER_PAGE}
                >
                    Next
                </Button>
            </div>
        </Container>
    );
}
