import React, { useEffect, useState } from 'react';
import {
    Badge, Button, Card, Container,
} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'wouter';
import { getPokemon } from './util/getPokemon';
import { Pokemon } from './types';
import { titleCase } from './util/titleCase';

export interface PokemonDetailsProps {
    pokemonRef: string
}

export default function PokemonDetails({ pokemonRef }: PokemonDetailsProps) {
    const [pokemon, setPokemon] = useState<Pokemon | null>(null);
    useEffect(() => {
        getPokemon(pokemonRef)
            .then((p) => setPokemon(p));
    }, [pokemonRef]);
    return (
        <Container className="mt-5 mb-5">
            <Link href="/">
                <Button variant="outline-dark" href="#">
                    ← Back to list
                </Button>
            </Link>
            <div className="mt-5 mb-3">
                {pokemon ? <h1>{titleCase(pokemon.name)}</h1> : <Skeleton height={41} width={300} />}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
            }}
            >
                {pokemon ? (
                    <>
                        <Card bg="dark" style={{ width: 'fit-content' }}>
                            <img
                                src={pokemon.sprites.front_default as string}
                                alt={`Front sprite for ${pokemon.name}`}
                                width={100}
                                height={100}
                            />
                        </Card>
                        <Card bg="dark" style={{ width: 'fit-content' }}>
                            <img
                                src={pokemon.sprites.back_default as string}
                                alt={`Back sprite for ${pokemon.name}`}
                                width={100}
                                height={100}
                            />
                        </Card>
                    </>
                ) : (
                    <>
                        <Skeleton height={98} width={102} />
                        <Skeleton height={98} width={102} />
                    </>
                )}
            </div>
            <div className="mt-3">
                {pokemon ? (
                    <p>{`${titleCase(pokemon.name)} has a weight of ${pokemon.weight} and a height of ${pokemon.height}.`}</p>
                ) : <Skeleton height={16} />}
            </div>
            <div
                className="mt-3"
                style={{
                    width: '100%',
                    display: 'grid',
                    gap: '20px',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(420px, 1fr))',
                }}
            >
                <div>
                    <h2>Moves</h2>
                    {pokemon ? (
                        pokemon.moves.map((m) => titleCase(m.move.name.replaceAll('-', ' '))).map((move) => (
                            <Badge bg="secondary" className="me-1">{move}</Badge>
                        ))
                    ) : (new Array(40)).fill('').map(() => <Skeleton className="me-1" height={16} width={Math.max(50, Math.round(Math.random() * 150))} />)}
                </div>
                <div>
                    <h2>Abilities</h2>
                    {pokemon ? (
                        pokemon.abilities.map((a) => titleCase(a.ability.name.replaceAll('-', ' '))).map((ability) => (
                            <Badge bg="secondary" className="me-1">{ability}</Badge>
                        ))
                    ) : (new Array(2)).fill('').map(() => <Skeleton className="me-1" height={16} width={Math.max(50, Math.round(Math.random() * 150))} />)}
                </div>
            </div>
        </Container>
    );
}
