import React from 'react';
import { Route } from 'wouter';
import './App.css';
import PokemonDetails from './PokemonDetails';
import PokemonList from './PokemonList';

function App() {
    return (
        <>
            <Route path="/" component={PokemonList} />
            <Route path="/pokemon/:pokemonRef">
                {(params) => <PokemonDetails pokemonRef={params.pokemonRef} />}
            </Route>
        </>
    );
}

export default App;
